(function ($) {
  "use strict";

  // if deluxe available then set it (premium for Holidays)
  // if ($('#optionThree').length) {

  //   $('input:radio[id=radio6]').prop('checked', true);
  //   $('#optionThree').addClass('checked');

  // } else if($('#optionTwo').length) {
  if ($("#optionTwo").length) {
    $("input:radio[id=radio5]").prop("checked", true);
    $("#optionTwo").addClass("checked");
  } else {
    //set Standard as default select
    $("input:radio[id=radio4]").prop("checked", true);
    $("#optionOne").addClass("checked");
  }

  $("#optionOne, #optionTwo, #optionThree").click(function () {
    var pickthis = $(this).data("pick");

    $("input:radio#" + pickthis).prop("checked", true);
    $(this).addClass("checked");
    $("li").not($(this)).removeClass("checked");

    console.log("clicked", $(this));
    // update the klarna installment price
    let installmentPrice = (
      parseFloat($(this).find(".option_price").text().replace(/[$C]/g, "")) / 4
    ).toFixed(2);
    let klarnaInstallmentsContainer = $(".klarna-installment-price");
    klarnaInstallmentsContainer.html(" $" + installmentPrice);
  });

  $("#o").val($("#holiday_occasion").val());

  $("a[data-reveal-id]").click(function () {
    setTimeout(() => {
      window.lazyLoader.revalidate();
    }, 300);
  });

  // Format decimal places
  var amount = $("span.price").html();
  var newamount = parseFloat(amount).toFixed(2);
  $("span.price").html(newamount);
})(jQuery);
